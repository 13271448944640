<template>
  <van-cell-group :title="title">
    <slot name="prepend"></slot>
    <van-loading size="24px" v-if="loading">加载中...</van-loading>
    <template v-else-if="form">
      <van-cell v-for="c in cells" :title="c.label.text" :key="c.id">{{getDisplayText(c)}}</van-cell>
    </template>
    <slot name="append"></slot>
  </van-cell-group>
</template>

<script>
import { getById, getData } from "@/api/dynamic/index";

export default {
  props: {
    title: String,
    id: String | Number,
    parentId: String | Number,
    readonly: Boolean,
  },
  data() {
    return {
      loading: true,
      form: null,
    };
  },
  watch: {
    id: {
      handler: "loadTemplate",
      immediate: true,
    },
  },
  methods: {
    analysisCells(items, cells) {
      if (items && items.length) {
        items.forEach((item) => {
          if (!item.hidden) {
            if (item.type === "layout") {
              this.analysisCells(item.items, cells);
            } else {
              cells.push(item);
            }
          }
        });
      }
    },
    loadTemplate() {
      this.loading = true;
      getById(this.id)
        .then((res) => {
          if (res && res.config) {
            const cfg = JSON.parse(res.config);
            const cells = [];
            if (cfg && cfg.form) {
              this.analysisCells(cfg.form.items, cells);
            }
            this.cells = cells;
            this.loadData(res.entityId);
          }
        })
        .catch((e) => {
          this.loading = false;
        });
    },
    loadData(entityId) {
      getData({
        current: 1,
        size: 1,
        entityId: entityId,
        conditions: [
          {
            fieldCode: "p_id",
            operator: "=",
            value: this.parentId,
          },
        ],
      })
        .then((res) => {
          if (res.content && res.content.length) {
            this.form = res.content[0];
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getDisplayText(cell) {
      const value = this.form[cell.prop];
      let text = "";
      switch (cell.component) {
        case "dyn-switch":
          if (cell.attributes) {
            text = value
              ? cell.attributes.activeText || "是"
              : cell.attributes.inactiveText || "否";
          }
          break;
        case "dyn-password":
          text = "********";
          break;
        case "dyn-date-picker":
          try {
            text = new Date(value).format(
              cell.attributes.format || "yyyy-MM-dd"
            );
          } catch (e) {
            text = value;
          }
          break;
        default:
          text = value;
          if (cell.options && cell.options.enabled && cell.options.dataSource) {
            if (cell.options.dataSource === "static") {
              const vf = cell.options.valueField,
                df = cell.options.displayField;
              const option = (cell.options.data || []).find(
                (o) => o[vf] === value
              );
              if (option) text = option[df];
            }
          }
          break;
      }
      return text;
    },
  },
};
</script>