import request from "@/utils/request";

export function getById(id) {
    return request({
        url: "api/testingForm/findById/" + id,
        method: "get",
    });
}

export default {
    getById,
}