<template>
  <div class="app-testing-task-view" v-if="task">
    <dyn-view title="送样信息" :id="task.sampleFormId" :parent-id="task.id" readonly />
    <van-cell-group title="检测信息">
      <van-cell title="检测部门">{{task.testingDeptName}}</van-cell>
      <van-cell title="检测人员">{{task.testingStaffName}}</van-cell>
      <van-cell title="检测设备">
        <span v-html="task.devs.map(o => `${o.devCode || ''}：${o.devName || ''} ${o.devModel || ''}`).join('<br />')"></span>
      </van-cell>
      <van-cell title="检测项目">{{task.itemName}}</van-cell>
      <van-cell title="当前状态">
        <van-tag :type="status[task.status].type">{{status[task.status].name}}</van-tag>
      </van-cell>
      <van-cell title="分配时间" v-if="task.status >= 1">{{task.assignTime}}</van-cell>
      <van-cell title="接收时间" v-if="task.status >= 2">{{task.acceptTime}}</van-cell>
      <van-cell title="检测时间" v-if="task.status >= 3">{{task.startTime}}</van-cell>
      <van-cell title="完成时间" v-if="task.status >= 4">{{task.finishTime}}</van-cell>
    </van-cell-group>
    <dyn-view title="检测结果" :id="task.resultFormId" :parent-id="task.id" readonly v-if="task.status >= 4" />
  </div>
</template>

<script>
import DynView from "@/views/dynamic/view";

export default {
  components: { DynView },
  props: {
    task: Object,
  },
  data() {
    return {
      status: [
        { id: 0, name: "待分配", type: "info" },
        { id: 1, name: "已分配", type: "warning" },
        { id: 2, name: "已接收", type: "primary" },
        { id: 3, name: "检测中", type: "primary" },
        { id: 4, name: "已完成", type: "success" },
      ],
    };
  },
};
</script>