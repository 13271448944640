import request from "@/utils/request";

export function getById(id) {
    return request({
        url: "api/formDef/queryById/" + id,
        method: "get",
    });
}

export function getData(data) {
    return request({
        url: "api/dyEntity/rec/page",
        method: "post",
        data,
    });
}

export default {
    getById,
    getData,
}