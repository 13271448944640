<template>
  <van-pull-refresh v-model="loading" class="app app-testing-detail bc-l" @refresh="load">
    <template v-if="form">
      <van-cell-group title="送检信息">
        <van-cell title="送检单号">{{form.code}}</van-cell>
        <van-cell title="送检类型">{{form.type === 0 ? "研发" : "生产"}}</van-cell>
        <van-cell title="送检部门">{{form.deptName}}</van-cell>
        <van-cell title="送检人员">{{form.staffName}}</van-cell>
        <van-cell title="项目/产品">{{form.projectName}}{{form.productName}}</van-cell>
        <van-cell title="实验/批次">{{form.expName}}{{form.productBatchNumber}}</van-cell>
        <van-cell title="送检时间">{{form.submissionTime}}</van-cell>
        <van-cell title="当前状态">
          <van-tag :type="status[form.status].type">{{status[form.status].name}}</van-tag>
        </van-cell>
      </van-cell-group>

      <van-tabs v-model="currentCheckItem" scrollspy sticky>
        <van-tab v-for="item in checkItems" :key="item.itemId" :title="item.itemName">
          <div class="item" v-for="(task, inx) in item.tasks" :key="task.id">
            <div class="no">{{inx + 1}}</div>
            <task-view :task="task" />
          </div>
        </van-tab>
      </van-tabs>
    </template>
  </van-pull-refresh>
</template>

<script>
import { getById } from "@/api/testing/form";
import TaskView from "./task/view";

export default {
  components: { TaskView },
  data() {
    return {
      id: null,
      form: null,
      checkItems: [],
      currentCheckItem: null,
      loading: false,
      status: [
        { id: 0, name: "待送样", type: "info" },
        { id: 1, name: "已送样", type: "warning" },
        { id: 2, name: "已接收", type: "primary" },
        { id: 3, name: "已退回", type: "danger" },
        { id: 4, name: "进行中", type: "primary" },
        { id: 5, name: "已完成", type: "success" },
      ],
    };
  },
  methods: {
    load() {
      if (this.id) {
        getById(this.id)
          .then((res) => {
            if (!res) return;
            this.form = res;
            let arr = [],
              obj = {};
            (res.items || []).forEach((item) => {
              if (!obj.hasOwnProperty(item.templateId)) {
                obj[item.templateId] = {
                  itemId: item.templateId,
                  itemName: item.itemName,
                  tasks: [],
                  assigning: false,
                  cancelAssigning: false,
                  assign:
                    this.form.type === 0
                      ? {
                          testingDeptId: this.form.deptId,
                          testingDeptName: this.form.deptName,
                          testingStaffId: this.form.staffId,
                          testingStaffName: this.form.staffName,
                          devs: null,
                        }
                      : {
                          testingDeptId: null,
                          testingDeptName: null,
                          testingStaffId: null,
                          testingStaffName: null,
                          devs: null,
                        },
                };
                arr.push(obj[item.templateId]);
              }
              obj[item.templateId].tasks.push(item);
            });
            this.checkItems = arr;
            this.currentCheckItem = arr[0].itemId;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
  mounted() {
    this.id = this.$route.params.id;
    this.load();
  },
};
</script>

<style lang="scss">
.app-testing-detail {
  overflow: hidden auto !important;

  .item {
    border-top: solid 5px var(--color-primary-light-2);
    display: flex;
    .no {
      padding: 16px 5px;
      line-height: 16px;
      flex: 0;
      flex-basis: initial;
      background-color: var(--color-primary-light-2);
    }
    .app-testing-task-view {
      flex: 1;
    }
  }
}
</style>